import { AfterViewChecked, AfterViewInit, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsConfigurationService } from 'ngx-permissions';
import { DecoratorService } from './shared/services/decorator.service';
import { DialogService } from './shared/services/dialog.service';
import { LanguageService } from './shared/services/language.service';
import { LoaderService } from './shared/services/loader.service';
import { SnackbarService } from './shared/services/snackbar.service';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { DOCUMENT } from '@angular/common';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  'Montserrat': {
    normal: `${window.location.origin}/assets/fonts/Montserrat-Regular.ttf`,
    bold: `${window.location.origin}/assets/fonts/Montserrat-Bold.ttf`,
    italics: `${window.location.origin}/assets/fonts/Montserrat-Italic.ttf`,
  },
  'Montserrat Alternates': {
    normal: `${window.location.origin}/assets/fonts/MontserratAlternates-Medium.ttf`,
    bold: `${window.location.origin}/assets/fonts/MontserratAlternates-Bold.ttf`,
  },
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked, AfterViewInit {
  title = 'frontend';

  constructor(
    private renderer: Renderer2,
    private ngxPermissionsConfigurationService: NgxPermissionsConfigurationService,
    private language: LanguageService,
    public _: DecoratorService,
    @Inject(DOCUMENT) private document: Document,

    /** Services to initialize: */
    private snackbarService: SnackbarService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    /** End */
  ) { }

  ngOnInit() {
    this.setPermissionStrategyForTemplate();
    this.language.init();
    const script = this.renderer.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDD2Sewsr20EK_G3g27NWY7hkKqJilZ_jo&libraries=places&language=en`;
    this.renderer.appendChild(document.body, script);
  }

  setPermissionStrategyForTemplate() {
    this.ngxPermissionsConfigurationService.addPermissionStrategy('disable', (templateRef) => {
      this.renderer.setAttribute(templateRef?.elementRef.nativeElement.previousSibling, 'readonly', 'true')
    })
    this.ngxPermissionsConfigurationService.addPermissionStrategy('enable', (templateRef) => {
      this.renderer.removeAttribute(templateRef?.elementRef.nativeElement.previousSibling, 'readonly');
    })
  }

  ngAfterViewInit() {
    this.applyDottedUnderlineToReadonlyInputs();
  }

  ngAfterViewChecked() {
    this.applyDottedUnderlineToReadonlyInputs();
  }

  applyDottedUnderlineToReadonlyInputs() {
    const inputs = this.document.querySelectorAll('mat-form-field input[readonly]');

    inputs.forEach(input => {
      const formField = input.closest('mat-form-field');
      const underline = formField?.querySelector('.mat-form-field-underline');

      if (underline) {
        // Apply complex background styles for dotted underline
        this.renderer.setStyle(underline, 'background-image', 'linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%)');
        this.renderer.setStyle(underline, 'background-size', '4px 100%');
        this.renderer.setStyle(underline, 'background-repeat', 'repeat-x');
        this.renderer.setStyle(underline, 'background-position', '0');
        this.renderer.setStyle(underline, 'background-color', 'rgba(0, 0, 0, 0)');
      }
    });
  }
}
